<script lang="ts" setup>
import { EffectFade, Autoplay, Pagination, Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore from 'swiper'
SwiperCore.use([Autoplay, Pagination])

import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'

import Slider1 from '@/components/Slider/Slider1.vue'
import Pagination2 from '@/components/Slider/Pagination2.vue'
import Link2 from '@/components/Button/Link2.vue'
import { type SliderList } from '@/utils/types'
import { usePageStore } from '@/stores'
const pageStore = usePageStore()
const blockData = computed(() => pageStore.homeSection1)
const sliderTimer: Ref<number> = ref(4000)

const imgLists: Ref<SliderList[]> = ref([])
const imgLargeLists: Ref<SliderList[]> = ref([])

blockData.value.forEach((o: any) => {
  const imgList: SliderList = {
    pic: o.image_lg.link,
    title: o.title,
    text: o.description,
    link: o.url
  }
  imgLists.value.push(imgList)
  const imgLargeList: SliderList = {
    pic: o.image_sm.link,
    title: o.title,
    text: o.description,
    link: o.url
  }
  imgLargeLists.value.push(imgLargeList)
})

const refSlideLeft = ref()

const refSlideRight = ref()

// swiper
const swiperSlideSelector = ref()
const onSwiper = (data: any) => {
  //存放 swiper
  swiperSlideSelector.value = data
}

const swiperTo = (index: number) => {
  swiperSlideSelector.value.slideTo(index)
}

const isSlideNext: Ref<boolean> = ref(true)

const handleSlideChange = (index: number) => {
  swiperTo(index)
  currentIndex.value = index
}
const handleSlidePrev = () => {
  isSlideNext.value = false
  refSlideLeft.value.slidePrevChange()
  refSlideRight.value.slidePrevChange()
}
const handleSlideNext = () => {
  isSlideNext.value = true
  refSlideLeft.value.slideNextChange()
  refSlideRight.value.slideNextChange()
}

const currentIndex: Ref<number> = ref(0)
const nextIndex: Ref<number> = ref(imgLists.value.length - 1)

const handlePaginationChange = (index: number) => {
  swiperTo(index)
  currentIndex.value = index
  nextIndex.value = index + 1
  if (nextIndex.value === imgLists.value.length) {
    nextIndex.value = 0
  }

  refSlideLeft.value.slideGoTo(currentIndex.value)
  refSlideRight.value.slideGoTo(nextIndex.value)
}

//mobile
const mobileCurrentIndex: Ref<number> = ref(0)

// swiper pic
const swiperMobilePicSlideSelector = ref()
const onSwiperMobilePic = (data: any) => {
  //存放 swiper
  swiperMobilePicSlideSelector.value = data
}

//swiper text
const swiperMobileTextSlideSelector = ref()
const onSwiperMobileText = (data: any) => {
  //存放 swiper
  swiperMobileTextSlideSelector.value = data
}

const handleMobileSlidePrev = () => {
  mobileCurrentIndex.value--
  if (mobileCurrentIndex.value === -1) {
    mobileCurrentIndex.value = imgLists.value.length - 1
  }
  swiperMobileTextSlideSelector.value.slideTo(mobileCurrentIndex.value)
}
const handleMobileSlideNext = () => {
  mobileCurrentIndex.value++
  if (mobileCurrentIndex.value === imgLists.value.length) {
    mobileCurrentIndex.value = 0
  }
  swiperMobileTextSlideSelector.value.slideTo(mobileCurrentIndex.value)
}

const handelSwiperMobileChange = (data: any) => {
  mobileCurrentIndex.value = data.realIndex
}
</script>

<template>
  <section class="about">
    <div class="wrap">
      <section class="about__pc">
        <div class="about__left">
          <Slider1 ref="refSlideLeft" :lists="imgLists" :timer="sliderTimer" :isGoNext="isSlideNext" direction="left"
            @update:slidechange="handleSlideChange" />
        </div>
        <div class="about__center">
          <Pagination2 :currentIndex="currentIndex" @update:change="handlePaginationChange" :totalIndex="imgLists.length"
            class="about__center__pagination" />
          <swiper :speed="1000" :loop="true" :modules="[EffectFade]" effect="fade" class="swiper-center"
            @swiper="onSwiper">
            <swiper-slide v-for="(item, index) in imgLists" :key="index">
              <div class="swiper-center__box">
                <div class="swiper-center__title">{{ item.title }}</div>
                <div class="swiper-center__text">{{ item.text }}</div>
                <div class="swiper-center__link">
                  <Link2 :to="item.link" class="circle" />
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="about__right">
          <div class="about__right__slide">
            <Slider1 ref="refSlideRight" :lists="imgLargeLists" :timer="sliderTimer" :isGoNext="isSlideNext" />
          </div>
          <div class="arrow">
            <div class="arrow__prev" @click="handleSlidePrev">
              <svg-icon name="icon-arrow-right2" />
            </div>
            <div class="arrow__next" @click="handleSlideNext">
              <svg-icon name="icon-arrow-right2" />
            </div>
          </div>
        </div>
      </section>
      <section class="about__mobile">
        <swiper :autoplay="{
          delay: sliderTimer
        }" :speed="1000" :loop="true" :modules="[EffectFade, Controller]"
          :controller="{ control: swiperMobileTextSlideSelector }" effect="fade" class="swiper-center"
          @swiper="onSwiperMobilePic">
          <swiper-slide v-for="(item, index) in imgLists" :key="index">
            <div class="swiper-center__box">
              <div class="swiper-center__pic">
                <Nimg :src="item?.pic" :alt="item.title" format="webp" />
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <Pagination2 :currentIndex="mobileCurrentIndex" @update:change="handlePaginationChange"
          :totalIndex="imgLists.length" class="about__mobile__pagination" />
        <!-- :autoplay="{
            delay: sliderTimer
          }" -->
        <swiper :speed="1000" :loop="true" :modules="[EffectFade, Controller]"
          :controller="{ control: swiperMobilePicSlideSelector }" effect="fade" class="swiper-center swiper-center-mobile"
          @swiper="onSwiperMobileText" @slide-change="handelSwiperMobileChange">
          <swiper-slide v-for="(item, index) in imgLists" :key="index">
            <div class="swiper-center__box">
              <div class="swiper-center__title">{{ item.title }}</div>
              <div class="swiper-center__text">{{ item.text }}</div>
              <div class="swiper-center__link">
                <Link2 :to="item.link" class="circle" />
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="arrow">
          <div class="arrow__prev" @click="handleMobileSlidePrev">
            <svg-icon name="icon-arrow-right2" />
          </div>
          <div class="arrow__next" @click="handleMobileSlideNext">
            <svg-icon name="icon-arrow-right2" />
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.about {
  padding-top: toRem(150);
  padding-bottom: toRem(110);

  @include max-media(1180) {
    padding-top: toRem(50);
  }

  @include max-media(990) {
    padding-bottom: toRem(50);
  }

  .wrap {
    @include grid(12, 24);

    @include max-media(990) {
      @include grid(4, 24);
      max-width: toRem(520);
    }
  }

  &__pc {
    @include grid;
    grid-column: 1 / span 12;

    @include max-media(990) {
      display: none;
    }
  }

  &__left {
    position: relative;
    grid-column: 1 / span 2;
    width: toRem(212);
    height: toRem(212);
    padding-top: 50%;
    overflow: hidden;
    object-fit: cover;
  }

  &__center {
    width: 100%;
    max-width: toRem(350);
    padding-top: toRem(202);
    grid-column: 3 / span 3;

    &__pagination {
      margin-bottom: toRem(24);
    }
  }

  &__right {
    grid-column: 8 / span 5;

    &__slide {
      position: relative;
      width: 100%;
      padding-top: 100%;
      overflow: hidden;
    }
  }

  &__mobile {
    grid-column: 1 / span 4;

    @include min-media(991) {
      display: none;
    }

    &__pagination {
      margin-bottom: toRem(25);
    }

    .arrow {
      margin-top: 0;
    }
  }
}

.swiper-center {
  &__box {
    transition: all 0.5s ease-in-out;
  }

  &__pic {
    margin-bottom: toRem(10);

    img {
      display: block;
      width: 100%;
    }
  }

  &__title {
    margin-bottom: toRem(10);
    font-size: toRem(22);
    font-weight: 700;
  }

  &__text {
    margin-bottom: toRem(10);
  }

  .swiper-slide {
    .swiper-center {
      &__box {
        opacity: 0;
      }
    }
  }

  .swiper-slide-active {
    .swiper-center {
      &__box {
        opacity: 1;
      }
    }
  }
}
</style>
<style lang="scss">
.swiper-center-mobile {
  display: flex;
  flex-direction: column;

  .swiper-pagination {
    order: -1;
    text-align: left;
  }
}
</style>
